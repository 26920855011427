<template>
  <form @submit.prevent="handleSubmit">
    <h3>Create bookmark</h3>
    <input type="text" required placeholder="title" v-model="bookTitle">
    <input type="text" required placeholder="url" v-model="bookUrl">
    <label>Upload bookmark image</label>
    <input type="file" required @change="handleChange">
    <div class="error" v-if="fileError"><span><i class="fas fa-exclamation-triangle"></i></span> {{ fileError }} fileError</div>
    <div class="error" v-if="error"><span><i class="fas fa-exclamation-triangle"></i></span> {{ error }}Error</div>
    <div class="form-buttons">
      <button type="submit" class="confirm" v-if="!isPending">Add bookmark</button>
      <button type="button" class="confirm" v-else disabled>Adding…</button>
      <button type="button" class="cancel" @click="closeCreate">Cancel</button>
    </div>
  </form>
</template>

<script>
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { ref } from 'vue'

export default {
  setup(props, context) {
    // composables
    const { filePath, url, uploadImage } = useStorage()
    const { error, addDoc } = useCollection('bookmarks')
    const { user } = getUser()

    //refs
    const bookUrl = ref('')
    const bookTitle = ref('')
    const file = ref(null)
    const fileError = ref(null)
    const isPending = ref(false)

    // event functions
    const handleSubmit = async () => {
      if (file.value) {
        isPending.value = true
        await uploadImage(file.value)
        const res = await addDoc({
          url: bookUrl.value,
          title: bookTitle.value,
          bookUrl: url.value,
          filePath: filePath.value,
          createdAt: timestamp(),
        })
        isPending.value = false
        if (!error.value) {
          bookUrl.value = ''
          bookTitle.value = ''
          file.value = null
          context.emit('closeCreate')
        }
      }
    }

    // allowed file types
    const types = ['image/png', 'image/jpeg']

    const handleChange = (evt) => {
      const selected = evt.target.files[0]
      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Please select an image file (png or jpg)'
      }
    }

    const closeCreate = () => {
      context.emit('closeCreate')
    }

    return {
      bookUrl,
      bookTitle,
      handleSubmit,
      handleChange,
      fileError,
      error,
      isPending,
      closeCreate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/_global.scss';

form {
  max-width: 480px;
  margin: 24vh auto 0;
  background-color: white;
  border-radius: $brad;
  padding: 24px;
  box-shadow: 2px 2px 4px $dark;
  h3 {
    color: $comp;
    font-size: 2.4rem;
    margin-bottom: 6px;
  }
  label {
    display: block;
    color: $comp;
    font-family: 'bold';
    margin: 8px 0 16px;
  }
  input[type='text'] {
    border: 1px solid rgba($comp, 0.2);
    background-color: $light;
    &:focus {
      border-color: $comp-lite;
    }
  }
  input[type='file'] {
    display: block;
    margin: 8px 0 16px;
  }
  .form-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 49%;
    }
    button:disabled {
      opacity: 0.3;
    }
  }
  button.confirm {
    color: white;
    background-color: $comp;
    &:hover {
      background-color: darken($comp, 10%);
    }
    &:active {
      color: $comp-lite;
      background-color: darken($comp, 5%);
    }
  }
  button.cancel {
    color: $comp-lite;
    background-color: rgba($comp-lite, 0.2);
    &:hover {
      color: $comp;
    }
    &:active {
      color: rgba($comp, 0.8);
    }
  }
}
</style>