<template>
      <li>
        <a href="" ><img src="@/assets/img/bookmarks/namecheap.png" alt=""></a>
        <span>petemcbride.ca</span>
      </li>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/_global.scss';
.bookmark-block {
  position: relative;
  max-width: 600px;
  margin: 48px 4% 24px;
  text-align: center;
}
</style>