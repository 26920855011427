<template>
  <div class="wallpaper" :class="isBlurred"></div>
  <div class="bookmark-block light-mode" :class="isBlurred">

    <section class="search-block">
      <div class="search-time">{{ todayHour }}:{{ todayMinute }}<span>{{ todayPeriod }}</span></div>
      <div class="search-date">{{ todayDay }}, {{ todayMonth }} {{ todayDate }}</div>
      <SearchForm />
    </section>

    <ul class="bookmark-list">
      <li v-for="doc in documents" :key="doc.id">
        <a :href="doc.url" ><img :src="doc.bookUrl"></a>
        <span>{{ doc.title }}</span>
      </li>
      <li class="bookmark-add" @click="openCreate = !openCreate"><button><span><i class="fas fa-plus"></i></span></button><span>add bookmark</span></li>
    </ul>

  </div>
  <div v-if="openCreate" class="modal-block">
    <BookmarkForm @closeCreate="closeCreate" />
  </div>
</template>

<script>
import BookmarkList from '@/components/lists/BookmarkList.vue'
import SearchForm from '@/components/forms/SearchForm.vue'
import BookmarkForm from '@/components/forms/BookmarkForm.vue'
import getCurrentDate from '@/composables/getCurrentDate'
import getCollection from '@/composables/getCollection'
import { computed, ref } from 'vue'

export default {
  components: { BookmarkList, SearchForm, BookmarkForm },
  setup() {
    const openCreate = ref(false)
    const { error, documents } = getCollection('bookmarks')

    const {
      todayDate,
      todayDay,
      todayMonth,
      updateClock,
      todayHour,
      todayMinute,
      todayPeriod,
    } = getCurrentDate()

    updateClock()
    setInterval(() => {
      updateClock()
    }, 10000)

    const isBlurred = computed(() => {
      return { blur: openCreate.value }
    })

    const closeCreate = () => {
      openCreate.value = !openCreate
    }

    return {
      todayDate,
      todayDay,
      todayMonth,
      todayMonth,
      todayHour,
      todayMinute,
      todayPeriod,
      openCreate,
      isBlurred,
      closeCreate,
      documents,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/_global.scss';
.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
div.wallpaper {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/img/wallpaper/dragonfly-short.png) center center;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 0;
}
div.modal-block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  background-color: rgba(black, 0.6);
}

.bookmark-block {
  position: relative;
  max-width: 600px;
  margin: 48px 4% 24px;
  text-align: center;
}
.dark-mode {
  .search-time {
    color: white;
  }
  .search-date {
    color: white;
  }
}
.light-mode {
  .search-time {
    color: $dark;
  }
  .search-date {
    color: $dark;
  }
  button {
    color: rgba($dark, 0.6);
    border: 2px solid rgba($dark, 0.4);
    background-color: rgba($dark, 0.3);
    &:hover {
      color: rgba($comp, 0.8);
      border-color: rgba($comp-lite, 1);
      background-color: rgba($comp, 0.3);
    }
  }
}

.search-time {
  text-shadow: 1px 1px 3px rgba($txt-dark, 0.6);
  font-size: 5.4rem;
  span {
    font-size: 3.2rem;
    padding-left: 5px;
  }
}
.search-date {
  color: white;
  text-shadow: 1px 1px 3px rgba($txt-dark, 0.6);
  font-size: 3.2rem;
}
ul.bookmark-list {
  position: relative;
  max-width: 600px;
  margin: 48px auto 24px;
  z-index: 201;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  li {
    display: inline-block;
    width: 25%;
    margin: 8px 0;

    a {
      display: block;
      border-radius: 20px;
      width: 82px;
      height: 82px;
      overflow: hidden;
      margin: 1px auto;
      border: 2px solid rgba($comp-lite, 0);
      img {
        width: 100%;
      }
      &:hover {
        border-color: rgba($comp-lite, 1);
      }
    }
    > span {
      display: block;
      font-size: 1.2rem;
      margin: 8px 4px 0;
    }
  }
  li.bookmark-add {
    button {
      width: 64px;
      height: 64px;
      font-size: 2.8rem;
      line-height: 1;
      border-radius: 16px;
      margin-top: 10px;
      margin-bottom: 9px;
    }
  }
}

@media screen and (min-width: 640px) {
  div.bookmark-block {
    margin: 16vh auto 24px;
  }
  ul.bookmark-list {
    li {
      width: 20%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
</style>