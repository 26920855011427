<template>
  <form>
    <span><i class="fas fa-search"></i></span>
    <input type="text" placeholder="Search…" v-model="searchTerm" @keypress.enter.prevent="handleSubmit">
  </form>
</template>

<script>
import { ref } from 'vue'
export default {
  setup() {
    const searchTerm = ref('')
    const handleSubmit = () => {
      window.open('https://duckduckgo.com/?q=' + searchTerm.value)
      searchTerm.value = ''
    }
    return { handleSubmit, searchTerm }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/_global.scss';

form {
  position: relative;
  margin: 24px 0;
  span {
    color: rgba($dark, 0.8);
    display: block;
    position: absolute;
    font-size: 2.4rem;
    top: 12px;
    left: 12px;
  }
  input {
    margin: 0;
    padding-left: 44px;
    font-size: 2rem;
    background-color: white;
    box-shadow: 1px 1px 3px rgba($txt-dark, 0.4);
    border: 2px solid white;
    &:focus {
      border: 2px solid $comp-lite;
    }
  }
}
</style>